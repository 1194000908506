require('./overrides.scss')

export default {
  theme: {
    themes: {
      light: {
        primary: '#00997d',
        accent: '#404040',
        secondary: '#0E3DC6',
        success: '#006404',
        info: '#007bff',
        warning: '#C98F0C',
        error: '#BD1D21',
        colorWhite: '#F2F4F4',
        colorBlack: '#3B3B3B',
        colorGrey: '#888888',
        colorLightGrey: '#A7A7A7',
      },
      dark: {
        primary: '#00997d',
        accent: '#404040',
        secondary: '#0E3DC6',
        success: '#30c513',
        info: '#007bff',
        warning: '#FFB400',
        error: '#BD1D21',
        colorWhite: '#F2F4F4',
        colorBlack: '#3B3B3B',
        colorGrey: '#888888',
        colorLightGrey: '#A7A7A7',
      },
    },
  },
}
