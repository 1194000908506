/* eslint-disable no-unused-vars */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import {
  cannotAccessList,
  defaultPage
} from '@/assets/cannotAccessList';
import TokenMixin from '@/mixins/TokenMixin'
import Validator from '@/prototype/Validator'
import Error from '@/views/Error.vue';

Vue.use(VueRouter);
Vue.mixin(TokenMixin)
Vue.use(Validator)
const vm = new Vue();

const routes = [{
    path: '/',
    redirect: 'login',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
      title: '登入',
      skipAuth: true,
    },
  },
  {
    path: '/setting',
    name: 'Setting',
    component: () => import('@/views/pages/admin/Setting.vue'),
    meta: {
      layout: 'content',
      title: '頁面管理',
      requireAuth: true,
      cannotAccess: cannotAccessList['Setting'],
    },
  },
  {
    path: '/news',
    name: 'NewsList',
    component: () => import('@/views/pages/admin/NewsList.vue'),
    meta: {
      layout: 'content',
      title: '最新消息',
      requireAuth: true,
      cannotAccess: cannotAccessList['NewsList'],
    },
  },
  {
    path: '/news/details/:id',
    name: 'NewsDetails',
    component: () => import('@/views/pages/admin/NewsDetails.vue'),
    meta: {
      layout: 'content',
      title: '最新消息詳情',
      requireAuth: true,
      showTitleAtSlot: true,
      cannotAccess: cannotAccessList['NewsDetails'],
    },
  },
  {
    path: '/news/create',
    name: 'CreateNews',
    component: () => import('@/views/pages/admin/NewsDetails.vue'),
    meta: {
      layout: 'content',
      title: '新增最新消息',
      requireAuth: true,
      showTitleAtSlot: true,
      cannotAccess: cannotAccessList['CreateNews'],
    },
  },
  {
    path: '/appointment',
    name: 'AppointmentList',
    component: () => import('@/views/pages/admin/AppointmentList.vue'),
    meta: {
      layout: 'content',
      title: '服務預約',
      requireAuth: true,
      cannotAccess: cannotAccessList['AppointmentList'],
    },
  },
  {
    path: '/appointment/details/:id',
    name: 'AppointmentDetails',
    component: () => import('@/views/pages/admin/AppointmentDetails.vue'),
    meta: {
      layout: 'content',
      title: '服務預約詳情',
      requireAuth: true,
      showTitleAtSlot: true,
      cannotAccess: cannotAccessList['AppointmentDetails'],
    },
  },
  {
    path: '/enquiry',
    name: 'EnquiryList',
    component: () => import('@/views/pages/admin/EnquiryList.vue'),
    meta: {
      layout: 'content',
      title: '客戶查詢',
      requireAuth: true,
      cannotAccess: cannotAccessList['EnquiryList'],
    },
  },
  {
    path: '*',
    name: 'Error',
    component: Error,
    meta: { title: 'Error' },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  store.dispatch('toggleLoadingPage', false);
  store.dispatch('setLoading', false);
  window.scrollTo(0, 0);

   if (to.meta.requireAuth) {
    const userData = vm.getCurrentUserData();
    const isUserLogin = vm.$validate.DataValid(userData);

    if (isUserLogin) {
      if (to.meta.cannotAccess && to.meta.cannotAccess.includes(userData.user_type)) {
        next(defaultPage[userData.user_type]);
      } else {
        next();
      }
    } else {
      next('/login');
    }
  } else if (to.meta.skipAuth) {
    const userData = vm.getCurrentUserData();
    if (vm.$validate.DataValid(userData) && userData.user_type) {
      if (to.name === 'login' || to.meta.cannotAccess && to.meta.cannotAccess.includes(userData.user_type)) {
        next(defaultPage[userData.user_type]);
      } else {
        next();
      }
    } else {
      next();
    }
  } else {
    next();
  }
})

const DEFAULT_TITLE = '晉賢物流 C & Y Logistics LTD CMS'

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = (`${to.meta.title} - ` || '') + DEFAULT_TITLE
  })
})

export default router
